@font-face {
  font-family: 'Light';
  src: local('EuclidCircularB-Light'), url(./fonts/EuclidCircularB-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Medium';
  src: local('EuclidCircularB-Medium'), url(./fonts/EuclidCircularB-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Bold';
  src: local('EuclidCircularB-Bold'), url(./fonts/EuclidCircularB-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #464a56;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

body.ReactModal__Body--open {
  overflow: hidden;
}

html {
  height: -webkit-fill-available;
}

@media screen and (max-width: 800px) {
  body {
    padding-bottom: 60px;
  }
  .desktop {
    display: none !important;
  }
}

@media screen and (min-width: 800px) {
  .mobile {
    display: none !important;
  }
}

#drift-frame-chat {
  bottom: 60px !important;
  right: 0px !important;
}

#drift-frame-controller {
  z-index: 1 !important;
  right: 0px !important;
  bottom: 0px !important;
}

@media screen and (max-width: 800px) {
  #drift-frame-controller {
    display: none !important;
  }
}

a,
button,
input,
textarea,
select,
div,
span,
label {
  font-family: 'Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #464a56;
  font-size: 14px;
}

textarea,
input[type='text'],
input[type='email'],
input[type='number'] {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input,
textarea,
select {
  font-family: 'Light';
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #b1b2b8;
}

a:focus,
button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 4pt #1d84ff50;
}

body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) div:focus {
  box-shadow: inherit;
}

.thumb:focus {
  outline: 0;
  box-shadow: 0 0 0 4pt #1d84ff50;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}
.disabled-link {
  pointer-events: none;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.hidden {
  display: none !important;
}

.inherit {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
}

/* ------------------------------------------------------------------------------------------------------------------ */

.ql-toolbar {
  border: 1px solid #eceff2 !important;
  border-radius: 10px 10px 0 0;
}

.ql-container {
  border: 1px solid #eceff2 !important;
  border-top: none !important;
  border-radius: 0 0 10px 10px;
}

.ql-editor {
  font-family: 'Light';
  padding: 16px;
  min-height: 200px;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  color: #b1b2b8 !important;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 10px !important;
}

/* ------------------------------------------------------------------------------------------------------------------ */

.OT_audio-level-meter__audio-only-img,
.OT_bar,
.OT_edge-bar-item {
  display: none !important;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.75em solid rgba(0, 122, 255, 0.2);
  border-right: 0.75em solid rgba(0, 122, 255, 0.2);
  border-bottom: 0.75em solid rgba(0, 122, 255, 0.2);
  border-left: 0.75em solid #1d84ff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.15s infinite linear;
  animation: load8 1.15s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.toggle.react-toggle--checked .react-toggle-track {
  background-color: #1d84ff;
  background-image: linear-gradient(to top, #1d84ff, #1d84ff);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #1d84ff !important;
  background-image: linear-gradient(to top, #1d84ff, #1d84ff) !important;
}

.toggle.react-toggle--checked .react-toggle-thumb {
  border-color: #1d84ff;
}

.toggle.react-toggle--checked .react-toggle-thumb {
  left: 28px !important;
}

.react-toggle-thumb {
  height: 20px !important;
  width: 20px !important;
  top: 2px !important;
  left: 2px !important;
  border: 0px !important;
}

.react-toggle-track {
  background-color: #d7d9db !important;
}

.pulsing-button {
  display: block;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  animation: pulse 3s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.tippy-tooltip--error-tooltip {
  background: #f5e6ec !important;
}

.tippy-tooltip--error-tooltip .tippy-tooltip-content {
  color: #ce4554 !important;
  font-family: 'Medium';
}

.tippy-tooltip--error-tooltip .arrow-regular {
  border-top: 7px solid #f5e6ec !important;
}

.tippy-popper {
  z-index: 1 !important;
}

.tippy-tooltip {
  background: #464a56;
  padding: 12px 16px !important;
  border-radius: 8px !important;
}

.tippy-popper[x-placement^='top'] [x-arrow] {
  border-top: 7px solid #464a56;
}

.tippy-tooltip-content {
  color: white;
  font-size: 12px;
  font-family: 'Light';
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-family: 'Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: #464a56 !important;
  border: 1px solid #cdd3d7 !important;
  border-radius: 12px !important;
  overflow: hidden;
}

.react-datepicker * > div {
  font-family: 'Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: #464a56 !important;
}

div.react-datepicker__day--selected {
  background-color: #1d84ff !important;
  color: white !important;
}

.react-datepicker__header {
  background-color: #f1f4f7 !important;
  border-bottom: 1px solid #cdd3d7 !important;
}

.react-datepicker__time-container {
  border-left: 1px solid #cdd3d7 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

li.react-datepicker__time-list-item--selected {
  background-color: #1d84ff !important;
  font-weight: normal;
}

.react-datepicker-popper {
  position: absolute !important;
  will-change: transform !important;
  top: 0px !important;
  left: 0px !important;
  transform: translate3d(14px, 74px, 0px) !important;
}

:root {
  --bs-body-line-height: normal !important;
}